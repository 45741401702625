@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* this is needed to make the content scrollable on larger screens */
@media (min-width: 576px) {
    .h-sm-100 {
        height: 100%;
    }
}

.test-breakpoints {
    background: rgba(247, 201, 241, 0.4);
    border-radius: 30px;
    left: 50%;
    padding: .5rem 1rem;
    position: fixed;
    top: 90%;
    transform: translate(-50%, -50%);
    z-index: 10000;
}

html, body {
    font-family: 'Nunito Sans', sans-serif;
    height: 100%;
}

#root {
    background-color: rgb(249, 251, 253);
    border-top: 2px solid rgb(44, 123, 229);
}

.bg-light {
    background-color: rgb(249, 251, 253) !important;
}

.bg-white {
    background-color: #fff;
}

.text-muted {
    color: rgb(149, 170, 201) !important;
}

.text-disabled {

}

.blur-container {
    display: grid;
}

.blur-content,
.blur-overlay {
    grid-area: 1 / 1;
}

.blur-overlay {
    z-index: 9999;
}

.blur-content {
    -webkikt-filter: url('#blurViewFilter');
    filter: url('#blurViewFilter');
    opacity: .3;
}

.global-message {
    border-radius: 0;
    border-width: 0;
    margin: 0;
    padding: 25px;
}

.global-message .alert-success {
    background-color: rgb(204, 247, 229);
}

.global-message .btn-close {
    font-size: 10px;
    padding-top: 44px;
}

.menu {
    background-color: white;
}

.nav-divider {
    border-bottom: 1px solid rgb(227, 235, 246);
    margin: 10px 0 15px 0;
    width: 90%;
}

.menu .company-name #companyList {
    color: rgb(110, 132, 163);
    font-size: 18px;
    font-weight: 500 !important;
    text-wrap: balance;
}

.menu .company-list {
    border: 0;
}

.logo {
    font-size: 34px;
    margin-bottom: 8px;
    margin-top: 8px;
    text-align: center;
}

/*.logo1 {*/
/*    font-weight: 700;*/
/*}*/

/*.logo2 {*/
/*    color: #2c7be5;*/
/*}*/

/*.logo3 {*/
/*    color: #2c7be5;*/
/*}*/

/*.logo4 {*/
/*    color: #2c7be5;*/
/*}*/

/******************************************************************************
Common components
******************************************************************************/

/* Used for integration accounting template. */

.x-ms-1 {
    padding-right: 0 !important;
}

.x-ms-2 {
    padding-right: 40px !important;
}

.x-ms-3 {
    padding-right: 40px !important;
}

thead tr th {
    color: rgb(149, 170, 201) !important;
    font-size: 11px;
    padding-bottom: 15px !important;
    padding-top: 15px !important;
    text-transform: uppercase;
}

/******************************************************************************
Public
******************************************************************************/

.public header {
    background-color: #fff;
    border-bottom: 1px solid rgb(227, 235, 246);
    border-top: 2px solid rgb(44, 123, 229);
    font-size: 15px;
    font-weight: 600;
}

.public header .logo-text-cfo {
    font-weight: 800;
}

.public header .logo-text-digital {
    color: rgb(86, 94, 100);
    font-weight: 400;
}

.public header .btn {
    border-radius: 1.5rem;
}

.public .logo img {
    height: 60px;
    margin-bottom: 36px;
}

.public h1,
.public .h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2em;
    text-align: center;
}

.public h2 {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.3em;
    text-align: center;
}

.public .faqs h2,
.public .privacy h2,
.public .terms h2 {
    text-align: left;
}

.public h3 {
    font-size: 18px;
}

.public .btn {
    border-radius: 1.5rem;
    font-size: .9em;
    min-width: 150px;
}

.public .btn-white {
    background-color: #fff;
    border: 1px solid rgb(149, 170, 201);;
    color: #2c7be5;
}

.public hr {
    border-bottom: 1px solid rgb(227, 235, 246) !important;
}

.public .header-subtitle {
    color: rgb(149, 170, 201);
    font-size: 18px;
    font-weight: normal;
    text-align: center;
}

/* Remove side margins. */
.public .home .bg-white {
    margin-left: -12px;
    margin-right: -12px;
}

.public .home .logo img {
    margin-bottom: 25px;
}

.public .home p {
    color: rgb(149, 170, 201);
}

.public .home .bi {
    color: #2c7be5;
    font-size: 36px;
}

.public .contact textarea {
    height: 200px !important;
}

.public .faqs .table td:nth-child(1),
.public .faqs .table td:nth-child(2) {
    width: 200px;
}

.public .faqs table,
.public .faqs ul {
    margin-bottom: 30px;
}

/******************************************************************************
Generic elements
******************************************************************************/

h3 {
    font-weight: bold;
}

a {
    text-decoration: none;
}

.bg-primary-subtle {
    background-color: rgb(213, 229, 250);
    color: rgb(44, 123, 229);
}

.alert-brand {
    background-color: #2c7be5;
    color: #fff;
}

/* Just to make sure all alert fonts are the same. */
.alert a {
    font-size: 16px !important;
    margin-left: 6px;
}

/* Increase to form-control-lg */
@media (max-width: 575px) {
    .form-control {
        border-radius: var(--bs-border-radius-lg);
        font-size: 1.25rem;
        min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
        padding: 0.5rem 1rem;
    }

    .form-select {
        font-size: 1.25rem;
        padding-bottom: 0.5rem;
        padding-left: 1rem;
        padding-top: 0.5rem;
    }
}

.btn.btn-primary:disabled {
    background-color: rgb(13, 110, 253, 0.7);
    border-color: rgb(13, 110, 253, 0.7);
}

h1 > .badge {
    bottom: 4px;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: .03em;
    margin-left: 12px;
    position: relative;
}

/******************************************************************************
Menu
******************************************************************************/

.menu .user-icon {
    height: 28px;
    width: 28px;
}

.menu a.logo {
    padding-bottom: 20px;
    padding-top: 12px;
}

.menu .logo img {
    height: 50px;
}

.menu {
    border-right: 1px solid rgb(227, 235, 246);
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.menu > div {
    padding-left: 12px;
    padding-right: 12px;
}

/* When sidebar collapses, unstick from bottom. */
@media (min-width: 575px) {
    .min-vh-100-sm {
        min-height: 100vh !important;
    }
}

.menu ul.nav {
    font-size: 15px;
    letter-spacing: -.01em;
}

.menu ul.nav li a {
    color: rgb(110, 132, 163);
}

.menu ul.nav li a i + span {
    margin-left: 6px;
}

/* TODO: Align nav center on small screen and remove text. */
@media (min-width: 575px) {
    .menu ul.nav {
        text-align: left;
    }
}

.menu .sign-out {
    margin-bottom: 10px;
}

.menu i {
    font-size: 1.2em;
}

.divider {
    padding-left: 6px;
    padding-right: 6px;
}

/******************************************************************************
Main
******************************************************************************/

.header {
    border-bottom: 1px solid rgb(227, 235, 246);
    margin-bottom: 18px;
    padding-bottom: 10px;
}

.header-pretitle {
    color: rgb(149, 170, 201);
    font-size: 10px;
    letter-spacing: .1em;
    text-transform: uppercase;
}

.header-title {
    color: rgb(18, 38, 63);
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -.01em;
}

.header-title > small {
    font-size: .8em;
    font-weight: 200;
    padding-left: 12px;
}

/* Loading spinner. */
.header-title > small > span {
    border-width: 2px;
    margin-left: 6px;
}

footer {
    color: rgb(110, 132, 163);
    font-size: 12px;
}

.form-label {
    font-size: 15px;
}

.form-error {
    margin-bottom: 30px;
}

.content .form-error {
    margin-top: 30px;
}

/* Icon + message. */
.form-error > i + span {
    padding-left: 6px;
}

/* Space between input groups on form. */
form fieldset div + div {
    margin-top: 24px;
}

/* Rows with cards showing statements. */
.analysis .row .row + .row {
    margin-top: 24px;
}

/* Chart notes. */
.analysis h5 {
    color: rgb(149, 170, 201);
    font-size: 18px;
    line-height: 1.5em;
    margin-top: 12px;
    text-align: center;
}

/*.recharts-surface {*/
/*    border: 1px solid red;*/
/*    height: 50px;*/
/*}*/

.analysis .custom-tooltip {
    background-color: #fff;
    border: 1px solid rgb(237, 242, 249);
    font-size: 14px;
    padding: 10px;
}

/******************************************************************************
nav
******************************************************************************/

/*nav {*/
/*    color: #fff;*/
/*}*/

/*nav .menu a {*/
/*    color: #fff;*/
/*    font-size: 14px;*/
/*    padding-left: 15px;*/
/*}*/

/*nav .menu a:visited {*/
/*    color: #fff;*/
/*}*/

/******************************************************************************
.modal-dialog
******************************************************************************/

.modal-dialog h3 {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 5px;
}

.modal-dialog p {
    font-size: 15px;
}

.modal-dialog p:last-child {
    margin-bottom: 5px;
}

/******************************************************************************
.public pages.
******************************************************************************/

.public ol {
    counter-reset: item;

}

.public p {
    margin-bottom: 9px;
}

/*ol ol > li {*/
/*    border: 1px solid red;*/
/*}*/

/*ol {*/
/*    counter-reset: item;*/
/*}*/

/*li {*/
/*    display: block;*/
/*}*/

/*li:before {*/
/*    content: counters(item, ".") " ";*/
/*    counter-increment: item;*/
/*}*/

/*.outer li {*/
/*    list-style-type: none;*/
/*}*/

/******************************************************************************
.account User accounts.
******************************************************************************/

.account.container {
}

.account .logo {
    font-size: 36px;
    margin-bottom: 24px;
    text-align: center;
}

.account .logo img {
    height: 60px;
}

.account .logo1 {
    font-weight: 700;
}

.account .logo2 {
    color: #2c7be5;
}

.account .logo3 {
    color: #2c7be5;
}

.account .logo4 {
    color: #2c7be5;
}

.account .card-body {
    padding: 30px;
}

.account h1 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: center;
}

.account fieldset > div {
    margin-bottom: 12px;
}

.account fieldset .form-floating + .form-floating {
    margin-top: 24px;
}

.account button {
    margin-top: 18px;
}

.account a {
    font-size: 14px;
}

/******************************************************************************
.account-settings User accounts.
******************************************************************************/

.account-settings .header {
    border-bottom-width: 0;
    margin-bottom: 0;
}

.account-settings h4 {
    font-size: 11px;
    font-weight: bold;
}

.account-settings .card-body {
    padding: 30px;
}

.account-settings .card-body .card-text {
    color: rgb(149, 170, 201);
    font-size: 11px;
    font-weight: bold;
    letter-spacing: .03em;
    text-transform: uppercase;
}

.account-settings .text-emphasis {
    font-size: 1.1em;
    font-weight: bold;
}

/******************************************************************************
footer
******************************************************************************/

/******************************************************************************
routes
******************************************************************************/

.header {
    margin-bottom: 30px;
}

.container {
    padding-bottom: 48px;
}

.container.loading-data {
    border: none;
    padding: 30px;
}

hr {
    border-top-width: 0.5px;
    margin-bottom: 30px;
    margin-top: 30px;
}

/*.generic-company {*/
/*    border: 1px solid red;*/
/*    max-height: 100px;*/
/*}*/

/******************************************************************************
other
******************************************************************************/

table th, td {
    font-size: 0.9em;
    padding: 0.7em !important;
}

table tfoot td {
    background-color: rgb(249, 251, 253) !important;
    font-weight: bold;
}

.card + .card {
    margin-top: 24px;
}

.card h3 {
    margin-bottom: 0;
}

.card h4 {
    font-weight: bold;
    text-transform: uppercase;
}

.card-header {
    border-bottom: 1px solid rgb(0, 0, 0, 0.125);
    padding: 15px;
}

/* KPIs vs Benchmarks */

.recharts-default-legend {
    margin-left: 50px !important;
}

.recharts-legend-item-text {
    font-size: 13px;
}

/* Empty container while fetching data. Height is same as first tab chart height. */
.charts-loading {
    min-height: 86px;
}

.charts-error {
    font-size: 40px;
    min-height: 86px;
}

.card-header {
    background-color: transparent !important;
}

/*.analysis > .row {*/
/*    margin-bottom: 24px;*/
/*}*/

/*.analysis .card {*/
/*    margin-bottom: 24px;*/
/*}*/

/*.tab-content {*/
/*    margin-top: 24px;*/
/*}*/

.nav-tabs .nav-link {
    background-color: transparent !important;
    border: none !important;
    color: rgb(149, 170, 201) !important;
    font-size: 14px;
}

.nav-tabs .nav-link.active {
    border-bottom: 1px solid #2c7be5 !important;
    color: inherit !important
}

.tab-content {
    margin-top: 30px;
}

.btn .spinner {
    display: inline-block;
    padding-left: 10px;
}

.export .spinner {
    display: inline-block;
    padding-left: 10px;
}

.container .loading-data {
    margin-bottom: 15px;
}

.container .btn + .btn {
    margin-left: 8px;
}

.loading-data span:first-child {
    padding-right: 5px;
}

/******************************************************************************
Modals
******************************************************************************/

.modal-title {
    color: rgb(18, 38, 63);
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -.01em;
}

/******************************************************************************
All
******************************************************************************/

.no-companies {
    border: 1px dashed rgb(0, 0, 0, 0.125);
    border-radius: 8px;
    padding: 30px;
}

.no-reports {
    border: 1px dashed rgb(0, 0, 0, 0.125);
    border-radius: 8px;
    padding: 30px;
}

.content .btn + .btn {
    margin-left: 8px;
}

/******************************************************************************
Reports
******************************************************************************/

/* Spacing for new report. */
/*.reports form fieldset .col div + div {*/
/*    margin-top: 15px;*/
/*}*/

.reports h3 {
    font-size: 20px;
}

.reports h4 {
    color: rgb(149, 170, 201);
    font-size: 10px;
    font-weight: bold;
    letter-spacing: .1em;
    margin-bottom: 24px;
    margin-top: 24px;
    text-transform: uppercase;
}

/* Report view. */

/* Rows with cards showing statements. */
.reports .tab-content .row + .row {
    margin-top: 24px;
}

.reports .loading-data {
    margin-bottom: 15px;
    margin-top: 15px;
}

.reports .reports-totals {
    font-weight: 600;
}

.reports input:not([type]),
.reports input[type=text] {
    text-align: right;
}

.reports .alert {
    margin-top: 16px;
}

.reports .alert ul {
    margin-bottom: 0;
}

.recharts-label-list text {
    color: red;
    font-size: 12px;
}

.reports .bi-info-circle {
    color: rgb(149, 170, 201);
    font-size: .75em;
}

/******************************************************************************
Public
******************************************************************************/

.public.contact {
    padding-left: 40px;
    padding-right: 40px;
}

.public.contact p {
    color: rgb(149, 170, 201);
    margin-bottom: 50px;
    text-align: center;
}

/******************************************************************************
Pricing
******************************************************************************/

/*.pricing .page-header p {*/
/*    color: rgb(149, 170, 201);*/
/*    padding-bottom: 9px;*/
/*    text-align: center;*/
/*}*/

.pricing .card-header {
    border: none;
    padding-top: 40px;
}

.pricing .card-header h2 {
    color: rgb(149, 170, 201);
    font-size: 11px;
    font-weight: bold;
    letter-spacing: .03em;
    margin-bottom: 0;
    text-transform: uppercase;
}

.pricing .card-title {
    font-size: 50px;
    font-weight: bold;
}

.pricing .card-title.contact {
    font-size: 25px;
    margin-top: 15px;
}

.pricing .card-body {
    min-height: 120px;
}

.pricing .card-body .card-title span {
    font-size: 18px;
    padding-right: 3px;
    vertical-align: middle;
}

.pricing .card-body .card-text {
    color: rgb(149, 170, 201);
    font-size: 11px;
    font-weight: bold;
    letter-spacing: .03em;
    text-transform: uppercase;
}

.pricing .card .list-group {
    font-size: 13px;
    margin: 30px;
    text-align: left;
}

.pricing .card-footer {
    background-color: #fff;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
}

/* For subscription wizard. */

.pricing .nav-tabs {
    border-bottom-color: transparent;
}

.pricing .nav-tabs .nav-link.active {
    border-width: 0 !important;
}

.pricing h2 {
    font-size: 24px;
    text-align: center;
}

.pricing .plan-period .btn-group {
    background: #fff;
    border: 1px solid rgb(237, 242, 249);
    border-radius: 100px;
    padding: 6px;
}

.pricing .plan-period .btn-outline-light {
    border-radius: 100px !important;
    color: rgb(149, 170, 201);
}

.pricing .plan-period .btn-outline-light:not(:checked) {
    border: none !important;
}

.pricing .plan-period .badge-outline-primary {
    background-color: #fff;
    border: 1px solid rgb(44, 123, 229);
    color: rgb(44, 123, 229);
}

.pricing .btn-outline-light {
    border-color: rgb(149, 170, 201);
    color: rgb(149, 170, 201);
}

.pricing .btn-light {
    color: rgb(149, 170, 201);
}

.pricing .btn-outline-light:hover {
    background-color: rgb(149, 170, 201) !important;
    border-color: rgb(149, 170, 201) !important;
    color: #fff !important;
}

.pricing .btn-check:checked + .btn {
    background-color: rgb(149, 170, 201);
    border-color: rgb(149, 170, 201);
    color: #fff;
}

.pricing li.list-group-item.category {
    color: rgb(149, 170, 201);
    font-size: 11px;
    font-weight: bold;
    letter-spacing: .03em;
    padding-top: 40px;
    text-transform: uppercase;
}

.summary {
    font-size: 25px;
}
